import React from 'react'
interface ModalExplanationProps {
    explanation: { [key: string]: any };
    setIsOffer: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalExplanation: React.FC<ModalExplanationProps> = ({ explanation, setIsOffer }) => {
    return (
        <div x-show="modalOpen" x-transition="" className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
            <div className="w-full max-w-142.5 rounded-lg bg-white px-8 py-12 dark:bg-boxdark md:p-5">
                <h3 className="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
                    {explanation?.heading}
                </h3>                {/* <span className="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span> */}
                <p className="mb-10 font-medium">
                    {explanation.explanation.map((explanin: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, idx: any) => (
                        <>
                            <p>{explanin}</p>
                            <br />
                        </>


                    ))}
                </p>
                <div className="-mx-3 flex justify-end flex-wrap gap-y-4">
                    <div className="px-3">
                        <button onClick={() => setIsOffer(false)} className="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1">
                            Cancel
                        </button>
                    </div>
                </div>
            </div >
        </div >

    )
}

export default ModalExplanation
