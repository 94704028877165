import React, { ReactNode, useState, createContext, FC } from 'react';

// Define the type for the context
interface User {
    [key:string]: string
}

interface UsersListContextProps {
    usersList: User[];
    setUsersList: React.Dispatch<React.SetStateAction<User[]>>;
}

// Provide a default value for the context
const defaultContext: UsersListContextProps = {
    usersList: [],
    setUsersList: () => { }, // No-op function
};

// Create the context with a default value
export const UsersListContext = createContext<UsersListContextProps>(defaultContext);

interface UsersListProviderProps {
    children: ReactNode;
}

// Define the UsersListProvider component
const UsersListProvider: FC<UsersListProviderProps> = ({ children }) => {
    const [usersList, setUsersList] = useState<User[]>([]);

    return (
        <UsersListContext.Provider value={{ usersList, setUsersList }}>
            {children}
        </UsersListContext.Provider>
    );
};

export default UsersListProvider;
