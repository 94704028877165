import AutoFill1 from '../../../images/product/autofill1.webp';
import AutoFill2 from '../../../images/product/autofill2.webp';
import FileFill1 from '../../../images/product/filefill1.webp';
import FileFill2 from '../../../images/product/filefill2.webp';
import FileFill3 from '../../../images/product/filefill3.webp';
import Profiles1 from '../../../images/product/profiles1.webp';
import Profiles2 from '../../../images/product/profiles2.webp';
import Profiles3 from '../../../images/product/profiles3.webp';
import Profiles4 from '../../../images/product/profiles4.webp';
import AutoProfilesCreation1 from '../../../images/product/autoprofilescreation1.webp';
import AutoProfilesCreation2 from '../../../images/product/autoprofilescreation2.webp';
import AutoProfilesCreation3 from '../../../images/product/autoprofilescreation3.webp';
import AutoProfilesCreation4 from '../../../images/product/autoprofilescreation4.webp';
import ViewProfile1 from '../../../images/product/viewprofile1.webp';
import ViewProfile2 from '../../../images/product/viewprofile2.webp';

//-------------------------AUTOFILL CONSTANTS------------------------------------//
export const autoFillImages = [{ id: 0, src: AutoFill1, alt: 'Slide 1' }, { id: 0, src: AutoFill2, alt: 'Slide 2' }];

export const autoFillPoints = [
  "1️⃣ Add Your Information: Enter key-value pairs like 'email: example@gmail.com' or 'name: John Doe'. These details will be used to automatically fill forms for you. The more you add, the easier form-filling becomes!",
  "2️⃣ Understands Similar Terms: Our system recognizes synonyms and related terms. For example, if you save 'name', it will also fill fields like 'full name' or 'first name' without any extra work.",
  "3️⃣ Fast and Simple: Once your details are saved, filling out forms takes just one click. Whether it's login pages or job applications, we'll handle the details for you.",
];

export const autoFillShortcut = {
  windows: 'CTRL + SHIFT + L',
  mac: 'CMD + SHIFT + L',
};

export const autofillDesc = "Autofill detects and fill web details for you"

export const autofillKeywords = "Autofill, chrome extension, detection, speed, optimized"

//-------------------------FileFILL CONSTANTS------------------------------------//
export const fileImages = [{ id: 0, src: FileFill1, alt: 'Slide 1' }, { id: 0, src: FileFill2, alt: 'Slide 2' }, { id: 0, src: FileFill3, alt: 'Slide 3' }];

export const fileFillPoints = [
    '1️⃣ Upload and Name Files: Save your important files by uploading them and assigning key names. For example, name a file "resume" or "ID Proof" for quick identification and use.',
    '2️⃣ One-Click File Uploads: When a website asks for file uploads, just use our shortcut to instantly select and upload your saved files—no need to browse or search manually.',
    '3️⃣ Secure and Private: Your files are stored safely on your local device. We ensure your privacy by not having any access to your uploaded files.',
  ];
  
export const fileFillShortcut = {
  windows: 'CTRL + SHIFT + L',
  mac: 'CMD + SHIFT + L',
};

export const filefillDesc = "FileFill detects file fields and uploads file on your behalf"

export const filefillKeywords = "File , detection, uploads, automatic"


//-------------------------PROFILE CONSTANTS------------------------------------//
export const profileImages = [{ id: 0, src: Profiles1, alt: 'Slide 1' },{ id: 0, src: Profiles2, alt: 'Slide 2' }, { id: 0, src: Profiles3, alt: 'Slide 3' }, { id: 0, src: Profiles4, alt: 'Slide 4' }];

export const profilePoints = [
  "1️⃣ Organize Multiple Profiles Easily: Quickly create and manage separate profiles for different clients or personal needs. Keep all details organized and ready to use without the hassle of mixing up information.",
  "2️⃣ Fast and Accurate Auto-Fill: Select a profile and let theQuickFill handle the rest. Auto-fill forms with saved details instantly, whether it's contact information or specific client data, saving you time and reducing mistakes.",
  "3️⃣ Secure and Private Storage: All your profiles are stored locally on your device. theQuickFill doesn't access your data, ensuring complete privacy and security.",
];

export const profileShortcut = {
  windows: 'CTRL + SHIFT + H',
  mac: 'CMD + SHIFT + H',
};

export const profileDesc = "Profiles helps to manage different profile for specfic clients"

export const profileKeywords = "Profiles, management, client management, client, detection, 2-click"

//-------------------------Auto PROFILE Creation CONSTANTS------------------------------------//
export const autoprofileCreationImages = [{ id: 0, src: AutoProfilesCreation1, alt: 'Slide 1' },{ id: 0, src: AutoProfilesCreation2, alt: 'Slide 1' }, { id: 0, src: AutoProfilesCreation3, alt: 'Slide 2' }, { id: 0, src: AutoProfilesCreation4, alt: 'Slide 3' }];

export const autoProfileCreationPoints = [
  "1️⃣ Effortless Profile Creation from Forms: Navigate to a website, fill out a form, and instantly convert the entered data into a reusable profile. No more repetitive typing—just save and reuse effortlessly! 💾✨",
  "2️⃣ Time-Saving for Frequent Users: Perfect for managing multiple clients or repetitive tasks. Skip the manual copying from notes—select a saved profile, and let it auto-fill forms in seconds, boosting productivity. 🕒🚀",
  "3️⃣ Centralized Data Management: Keep all client details in one place, neatly organized and ready to use whenever needed. No more juggling between notes and documents—access everything with a single click! 📂✅",
];

export const autoprofileCreationShortcut = {
  windows: 'CTRL + SHIFT + H',
  mac: 'CMD + SHIFT + H',
};

export const autoprofileCreationDesc = "Create profiles instead of manually typing and saving profiles in TheQuickFill"

export const autoprofileCreationKeywords = "Profiles, autoCreation, speed, ease"


//-------------------------View Profile CONSTANTS------------------------------------//
export const viewProfileImages = [{ id: 0, src: ViewProfile1, alt: 'Slide 1' }, { id: 0, src: ViewProfile2, alt: 'Slide 2' }];

export const viewProfilePoints = [
  "1️⃣ Not every form is predictable. If a field isn’t auto-filled, don’t stress. 💾✨",
  "2️⃣ Quickly access all saved key-value pairs from your profile in a neat, readable format. 🕒🚀",
  "3️⃣ One-click copy-paste makes manual input faster and error-free for unmatched convenience.📂✅",
];

export const viewProfileShortcut = {
  windows: 'CTRL + SHIFT + V',
  mac: 'CMD + SHIFT + V',
};

export const viewProfileDesc = "QuickView for your generalProfile"

export const viewProfileKeywords = "QuickView, generalProfile, copy-paste"


//-------------------------SHOWPASSWORD CONSTANTS------------------------------------//
export const showPasswordPoints = [
  "🛠️ Click 'Show Password' to show the password as sometimes website doesn't provide option with show.",
];
export const showPasswordShortcut = {
  windows: 'CTRL + SHIFT + K',
  mac: 'CMD + SHIFT + K',
};

export const showPasswordDesc = "Shows hidden password"

export const showPasswordKeywords = "password, hidden, detection"
