import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import React, { useState } from 'react';
import { pricingPlans } from './PricingPlan';
import checkedCross from '../../images/icon/checked-cross.svg'
import { useNavigate } from 'react-router-dom';
import ModalExplanation from '../../components/ModalExplanation';


interface PricingProps {
}
const Pricing: React.FC<PricingProps> = ({ }) => {
    const [isOffer, setIsOffer] = useState<boolean>(false)
    const navigate = useNavigate();

    const handlePurchase = (plan: any) => {
        // Navigate to the checkout page and pass the selected plan as state
        navigate('/pricing/checkout', { state: { plan } });
    };

    const handleEnterprisePurchase = () => {
        navigate('/contactUs')
    }

    return (
        <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
            <Breadcrumb pageName='Pricing' />

            <div className="flex flex-col gap-5 md:gap-7 2xl:gap-10">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
                    {pricingPlans.map((plan, index) => (
                        <div
                            key={index}
                            className="relative rounded-sm border border-stroke bg-white p-6 shadow-default dark:border-strokedark dark:bg-boxdark md:p-9 xl:p-11.5"
                        >
                            <span className="absolute top-3.5 -right-1">
                                <svg
                                    width="150"
                                    height="34"
                                    viewBox="0 0 150 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M24 0L146 0C147.657 0 149 1.34315 149 3V30L24 30L24 0Z"
                                        fill="#3C50E0"
                                    ></path>
                                    <foreignObject x="24" y="0" width="122" height="30">
                                        <div>
                                            <div className="mt-1 text-center text-sm font-medium text-white">
                                                {plan.title != 'Enterprise' ? 'Limited Time Offer' : 'Enterprise plan'}
                                            </div>
                                        </div>
                                    </foreignObject>
                                    <path
                                        d="M0 0H24V30H0L19 15L0 0Z"
                                        fill="#3C50E0"
                                    ></path>
                                    <path d="M145 34L149 30H145V34Z" fill="#2539C8"></path>
                                </svg>
                            </span>

                            {plan.isBestValue && (
                                <span className="absolute top-15 -right-1">
                                    <svg
                                        width="109"
                                        height="34"
                                        viewBox="0 0 109 34"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M24 0L106 0C107.657 0 109 1.34315 109 3V30L24 30L24 0Z"
                                            fill="#3C50E0"
                                        ></path>
                                        <foreignObject x="24" y="0" width="81" height="30">
                                            <div>
                                                <div className="mt-1 text-center text-sm font-medium text-white">
                                                    Best Value
                                                </div>
                                            </div>
                                        </foreignObject>
                                        <path
                                            d="M0 0H24V30H0L19 15L0 0Z"
                                            fill="#3C50E0"
                                        ></path>
                                        <path d="M105 34L109 30H105V34Z" fill="#2539C8"></path>
                                    </svg>
                                </span>
                            )}

                            <span className="mb-2.5 block text-xl font-bold text-blue-800 dark:text-white">
                                {plan.title}
                            </span>
                            {plan.title != 'Enterprise' ?
                                <>
                                    <h1 className="font-extrabold">

                                        <span className="text-4xl pr-2 font-bold text-black dark:text-white">
                                            ₹ {plan.price}
                                        </span>
                                        <span className="font-medium text-sm">{plan.validity}</span>
                                    </h1>
                                    <span className="font-medium text-center"><del>₹{plan.actual_price}</del></span>
                                </>

                                :
                                <button
                                    onClick={() => handleEnterprisePurchase()}
                                    className="mt-9 flex rounded-md border border-primary py-3 px-16 font-medium text-primary hover:bg-primary hover:text-white"
                                >
                                    Contact Us
                                </button>
                            }
                            <h4 className="mt-7.5 mb-5 text-lg font-medium text-black dark:text-white">
                                Features
                            </h4>
                            <ul className="flex flex-col gap-3.5">
                                {plan.features?.map((feature, i) =>
                                    typeof feature === 'string' ? (
                                        <li key={i} className="font-medium flex gap-2 ">
                                            <img src={checkedCross} alt="Icon" /> <span>{feature}</span>
                                        </li>
                                    ) : (
                                        <li key={i} className="font-medium flex gap-2">
                                            <img src={checkedCross} alt="icon" />
                                            <div className='flex flex-wrap gap-1'>
                                                <span>{feature?.text}</span>

                                                {feature.products.map((product, productId) => (
                                                    <a href={product.link} className="text-blue-600">
                                                        {product.name}
                                                        {productId < feature.products.length - 1 && ','}&nbsp;

                                                    </a>

                                                ))}
                                            </div>

                                        </li>
                                    )
                                )}
                                {plan.offers?.map((offer, i) => (

                                    <li key={i} className="font-medium flex gap-2">
                                        <img src={checkedCross} alt="icon" />
                                        <div className='flex items-center gap-1'>
                                            <span>{offer?.text}</span>
                                            <span onClick={() => setIsOffer(true)} className='cursor-pointer'>❓</span>
                                            {
                                                isOffer && <ModalExplanation explanation={offer} setIsOffer={setIsOffer}/>
                                            }

                                        </div>

                                    </li>
                                ))}

                            </ul>
                            {plan.title != 'Enterprise' &&
                                <button
                                    onClick={() => handlePurchase(plan)}
                                    className="mt-9 flex rounded-md border border-primary py-3 px-9 font-medium text-primary hover:bg-primary hover:text-white"
                                >
                                    Purchase Now
                                </button>
                            }
                        </div>
                    ))}
                </div>


            </div>
        </div>

    );
};

export default Pricing;
