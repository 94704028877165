import React, { useState } from "react";
import FeatureThumbnail from "../../images/product/features.webp"
import YoutubeVideoIcon from "../../images/social/youtube.svg"

const HomeImage: React.FC = () => {
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);

    // Replace with the video thumbnail URL (YouTube's generated thumbnail)

    const loadIframe = () => {
        setIsIframeLoaded(true);
    };

    return (
        <div className="col-span-12 h-fit rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">
            <div className="h-fit rounded-lg flex flex-col items-center gap-2">
                {!isIframeLoaded ? (
                    <div
                        className="relative w-full h-96 cursor-pointer"
                        onClick={loadIframe}
                    >
                        {/* Thumbnail as placeholder */}
                        <img
                            src={FeatureThumbnail}
                            alt="QuickFill Video Thumbnail"
                            className="w-full h-full object-cover rounded-lg"
                        />
                        {/* Play Button Overlay */}
                        <div className="absolute inset-0 flex items-center justify-center items-center bg-black bg-opacity-50 rounded-lg">
                            <button className="flex justify-center ytp-large-play-button ytp-button ytp-large-play-button-red-bg" aria-label="Play" title="Play">
                                <img src={YoutubeVideoIcon} alt="youtube icon"/>
                            </button>
                        </div>
                    </div>
                ) : (
                    <iframe
                        className="w-full h-96 rounded-lg"
                        src="https://www.youtube.com/embed/2DncJp5Pxio?autoplay=1"
                        title="QuickFill"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
        </div>
    );
};

export default HomeImage;
