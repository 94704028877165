import React from 'react';
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb';
import QuickFillImage from '../common/QuickFillImage';
import QuickFillDesc from '../common/QuickFillDesc';
import { profileDesc, profileImages, profileKeywords, profilePoints, profileShortcut } from '../constants/constants';


const Profiles = () => {
    return (
        <>
            <Breadcrumb pageName="Profiles" description={profileDesc} keywords={profileKeywords}/>

            {/* <!-- ====== FileFill Section Start ====== --> */}
            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <QuickFillImage quickFillImages={profileImages}/>

                <QuickFillDesc quickFillPoints={profilePoints} headerText="Advance AutoFill" quickFillShortcut={profileShortcut}/>

            </div>


            {/* <!-- ====== FileFill Section End ====== --> */}
        </>
    );
};

export default Profiles;
