import React, { useContext, useState } from "react";
import UserActions from "./UserActions";
import { UsersListContext } from "../../context/UsersListContext";

const UsersList = () => {
    const { usersList, setUsersList } = useContext(UsersListContext)

    return (
        <div className="mx-auto max-w-screen-2xl py-4 md:py-6 2xl:py-10">
            <div className="w-full overflow-x-auto">
                <div className="">
                    {/* Table Header */}
                    <div className="grid grid-cols-12 rounded-t-[10px] bg-primary px-5 py-4 lg:px-7.5 2xl:px-11">
                        <div className="col-span-7">
                            <h5 className="font-medium text-white">Email</h5>
                        </div>
                        {/* <div className="col-span-2">
                            <h5 className="font-medium text-white">Name</h5>
                        </div> */}
                        <div className="col-span-5">
                            <h5 className="float-right font-medium text-white">Edit</h5>
                        </div>
                    </div>
                    {/* Table Body */}
                    <div className="bg-white dark:bg-boxdark rounded-b-[10px]">
                        {usersList.map((row, index) => (
                            <UserActions index={index} row={row} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsersList;
