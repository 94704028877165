import React, { ReactNode, useState, createContext, FC } from 'react';

// Define the type for the context
interface User {
    [key:string]: string
}

interface SubscriptionsListContextProps {
    subscriptionsList: User[];
    setSubscriptionsList: React.Dispatch<React.SetStateAction<User[]>>;
}

// Provide a default value for the context
const defaultContext: SubscriptionsListContextProps = {
    subscriptionsList: [],
    setSubscriptionsList: () => { }, // No-op function
};

// Create the context with a default value
export const SubscriptionsListContext = createContext<SubscriptionsListContextProps>(defaultContext);

interface SubscriptionsListProviderProps {
    children: ReactNode;
}

// Define the SubscriptionsListProvider component
const SubscriptionsListProvider: FC<SubscriptionsListProviderProps> = ({ children }) => {
    const [subscriptionsList, setSubscriptionsList] = useState<User[]>([]);

    return (
        <SubscriptionsListContext.Provider value={{ subscriptionsList, setSubscriptionsList }}>
            {children}
        </SubscriptionsListContext.Provider>
    );
};

export default SubscriptionsListProvider;
