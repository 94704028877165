import React, { useContext, useEffect, useState } from 'react';
import { getAllData } from '../../service/api';
import { UsersListContext } from '../../context/UsersListContext';
import { SubscriptionsListContext } from '../../context/SubscriptionsList';
import UserStats from './UserStats';
// import ChartOne from '../../components/Charts/ChartOne';
// import ChartThree from '../../components/Charts/ChartThree';
// import ChartTwo from '../../components/Charts/ChartTwo';
// import ChatCard from '../../components/Chat/ChatCard';
// import MapOne from '../../components/Maps/MapOne';
// import TableOne from '../../components/Tables/TableOne';

const Admin: React.FC = () => {
    const { usersList, setUsersList } = useContext(UsersListContext)
    const { subscriptionsList, setSubscriptionsList } = useContext(SubscriptionsListContext)

    const [userStats, setUserStats] = useState({ userCount: 0 })
    const [subscriptionStats, setSubscriptionStats] = useState({ subscriptionCount: 0 })

    const [openTab, setOpenTab] = useState(1);

    const activeClasses =
        "bg-primary text-white rounded-md text-sm font-medium md:text-base p-2";
    const inactiveClasses =
        "bg-gray dark:bg-meta-4 text-black dark:text-white rounded-md p-2 text-sm font-medium hover:bg-primary hover:text-white dark:hover:bg-primary md:text-base";

    useEffect(() => {
        const fetchData = async () => {
            const usersList_temp = await getAllData();
            const { usersList, subscriptionsList } = usersList_temp
            setUsersList(usersList)
            setSubscriptionsList(subscriptionsList)
            setSubscriptionStats({ ...subscriptionStats, subscriptionCount: subscriptionsList.length })
            setUserStats({ ...userStats, userCount: usersList.length })
        }
        fetchData()

    }, [])

    return (
        <>
            <div className="mb-7.5 flex flex-wrap gap-3 rounded-lg border border-stroke px-4 py-3 dark:border-strokedark">
                <button
                    onClick={() => setOpenTab(1)}
                    className={openTab === 1 ? activeClasses : inactiveClasses}
                >
                    UserStats
                </button>
                <button
                    onClick={() => setOpenTab(2)}
                    className={openTab === 2 ? activeClasses : inactiveClasses}
                >
                    Add Admin
                </button>
                <button
                    onClick={() => setOpenTab(3)}
                    className={openTab === 3 ? activeClasses : inactiveClasses}
                >
                    Our Team
                </button>
                <button
                    onClick={() => setOpenTab(4)}
                    className={openTab === 4 ? activeClasses : inactiveClasses}
                >
                    Company Details
                </button>
            </div>
            {openTab == 1 && <UserStats />}
        </>
    );
};

export default Admin;
