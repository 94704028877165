import React from 'react';
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb';
import QuickFillImage from '../common/QuickFillImage';
import QuickFillDesc from '../common/QuickFillDesc';
import { viewProfilePoints, viewProfileShortcut, viewProfileImages, viewProfileDesc, viewProfileKeywords } from '../constants/constants';


const ViewProfile = () => {
    return (
        <>
            <Breadcrumb pageName="ViewProfile" description={viewProfileDesc} keywords={viewProfileKeywords}/>

            {/* <!-- ====== ViewProfile Section Start ====== --> */}
            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <QuickFillImage quickFillImages={viewProfileImages}/>

                <QuickFillDesc quickFillPoints={viewProfilePoints} headerText="View Profile" quickFillShortcut={viewProfileShortcut}/>

            </div>


            {/* <!-- ====== ViewProfile Section End ====== --> */}
        </>
    );
};

export default ViewProfile;
