import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";
import LinkedIn from '../images/social/linkedin.webp'
import Gmail from '../images/social/gmail.webp'


const ContactUs = () => {


    // if (loading) return <Loader />
    return (
        <>
            <Breadcrumb pageName="Get In Touch With Us" pageLink="ContactUs" />
            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <div className="col-span-12 rounded-sm border border-stroke bg-white px-2 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-6">
                    <div className="mb-8 flex w-full gap-4">
                        <img src={Gmail} width={45} style={{ height: 45 }} alt="Gmail"/>
                        <div className="w-full flex-col">
                            <h4 className="text-lg font-bold text-dark dark:text-white">
                                Email
                            </h4>
                            <p className="text-base text-body-color dark:text-dark-6">
                                neelmehta@thequickfill.com
                            </p>
                        </div>
                    </div>


                    <div className="mb-8 flex w-full gap-4">
                        <img src={LinkedIn} width={45} style={{ height: 45 }} alt="LinkedIn"/>

                        <div className="w-full">
                            <h4 className="text-lg font-bold text-dark dark:text-white">
                                LinkedIn
                            </h4>
                            <a href="https://www.linkedin.com/company/thequickfill/about/" target="_blank">
                                <p className="text-base text-blue-500 dark:text-dark-6">
                                    company/thequickfill
                                </p>
                            </a>
                        </div>
                    </div>
                </div >


                <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-6">
                    <div className="flex flex-col gap-7.5 p-1 sm:p-1 xl:p-1">

                        <h2 className="text-xl font-semibold text-dark dark:text-white">
                            Reach Out to Us
                        </h2>


                        <p className="text-base text-body-color dark:text-dark-6">
                            Feel free to reach out to us via email for any general inquiries or customer support needs. We aim to respond to all emails within 24 hours.
                        </p>


                        <p className="text-base text-body-color dark:text-dark-6">
                            For more immediate assistance or to stay updated with our latest news and announcements, follow us on LinkedIn.
                        </p>


                        <p className="text-base text-body-color dark:text-dark-6">
                            We appreciate your interest in our company and look forward to connecting with you.
                        </p>
                    </div>
                </div>


            </div>
        </>
    )

}
export default ContactUs;